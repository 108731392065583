import { createContext, useCallback, useContext, useState } from 'react'
import getCroppedImg from 'lib/cropImage'

const defaultImage = null
const defaultCrop = { x: 0, y: 0 }
const defaultRotation = 0
const defaultZoom = 1
const defaultCroppedAreaPixels = null

export const ImageCropContext = createContext<{
  image: string | null
  setImage: (image: string) => void
  zoom: number
  setZoom: React.Dispatch<React.SetStateAction<number>>
  rotation: number
  setRotation: React.Dispatch<React.SetStateAction<number>>
  crop: {
    x: number
    y: number
  }
  setCrop: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>
  croppedAreaPixels: {
    x: number
    y: number
    width: number
    height: number
  } | null
  setCroppedAreaPixels: React.Dispatch<
    React.SetStateAction<{
      x: number
      y: number
      width: number
      height: number
    } | null>
  >
  onCropComplete: (
    croppedArea: { x: number; y: number; width: number; height: number },
    croppedAreaPixels: { x: number; y: number; width: number; height: number },
  ) => void
  getProcessedImage: () => Promise<File | null>
  handleZoomIn: () => void
  handleZoomOut: () => void
  handleRotateCw: () => void
  handleRotateAntiCw: () => void
  max_zoom: number
  min_zoom: number
  zoom_step: number
  max_rotation: number
  min_rotation: number
  rotation_step: number
  resetStates: () => void
  shape?: `round` | `rect`
  setShape?: React.Dispatch<React.SetStateAction<`round` | `rect`>>
  aspectRatio?: number
  setAspectRatio?: React.Dispatch<React.SetStateAction<number>>
}>({
  image: defaultImage,
  setImage: () => {},
  zoom: defaultZoom,
  setZoom: () => {},
  rotation: defaultRotation,
  setRotation: () => {},
  crop: defaultCrop,
  setCrop: () => {},
  croppedAreaPixels: defaultCroppedAreaPixels,
  setCroppedAreaPixels: () => {},
  onCropComplete: () => {},
  getProcessedImage: async () => null,
  handleZoomIn: () => {},
  handleZoomOut: () => {},
  handleRotateCw: () => {},
  handleRotateAntiCw: () => {},
  max_zoom: 3,
  min_zoom: 1,
  zoom_step: 0.1,
  max_rotation: 360,
  min_rotation: 0,
  rotation_step: 5,
  resetStates: () => {},
  shape: `round`,
  aspectRatio: 1,
})

const ImageCropProvider = ({
  children,
  max_zoom = 3,
  min_zoom = 1,
  zoom_step = 0.1,
  max_rotation = 360,
  min_rotation = 0,
  rotation_step = 5,
}) => {
  const [image, setImage] = useState<string | null>(defaultImage)
  const [crop, setCrop] = useState(defaultCrop)
  const [rotation, setRotation] = useState(defaultRotation)
  const [zoom, setZoom] = useState(defaultZoom)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    x: number // x/y are the coordinates of the top/left corner of the cropped area
    y: number
    width: number // width of the cropped area
    height: number // height of the cropped area
  } | null>(defaultCroppedAreaPixels)
  const [shape, setShape] = useState<`round` | `rect`>(`round`)
  const [aspectRatio, setAspectRatio] = useState<number>(1)

  const onCropComplete = useCallback(
    (
      _croppedArea: { x: number; y: number; width: number; height: number },
      croppedAreaPixels: { x: number; y: number; width: number; height: number },
    ) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    [],
  )

  const handleZoomIn = () => {
    if (zoom < max_zoom) {
      setZoom(zoom + zoom_step * 2)
    }
  }

  const handleZoomOut = () => {
    if (zoom > min_zoom) {
      setZoom(zoom - zoom_step * 2)
    }
  }

  const handleRotateCw = () => {
    setRotation(rotation + rotation_step)
  }

  const handleRotateAntiCw = () => {
    setRotation(rotation - rotation_step)
  }

  const getProcessedImage = async () => {
    if (image && croppedAreaPixels) {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation)
      if (!croppedImage) {
        return null
      }
      const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
        type: `image/png`,
      })
      return imageFile
    } else {
      return null
    }
  }

  const resetStates = () => {
    setImage(defaultImage)
    setCrop(defaultCrop)
    setRotation(defaultRotation)
    setZoom(defaultZoom)
    setCroppedAreaPixels(defaultCroppedAreaPixels)
  }

  const handleSetImage = (image: string) => {
    setImage(image)
  }

  return (
    <ImageCropContext.Provider
      value={{
        image,
        setImage: handleSetImage,
        zoom,
        setZoom,
        rotation,
        setRotation,
        crop,
        setCrop,
        croppedAreaPixels,
        setCroppedAreaPixels,
        onCropComplete,
        getProcessedImage,
        handleZoomIn,
        handleZoomOut,
        handleRotateAntiCw,
        handleRotateCw,
        max_zoom,
        min_zoom,
        zoom_step,
        max_rotation,
        min_rotation,
        rotation_step,
        resetStates,
        shape,
        setShape,
        aspectRatio,
        setAspectRatio,
      }}
    >
      {children}
    </ImageCropContext.Provider>
  )
}

export const useImageCropContext = () => useContext(ImageCropContext)

export default ImageCropProvider
